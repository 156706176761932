import React from 'react';
import styled from 'styled-components';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import UpdateAt from '@beelineloans/cx-library/src/components/layout/UpdateAt';
import { H2, H3, Paragraph, Span } from '@beelineloans/cx-library/src/components/typography';
import { ButtonText } from '../../../../shared/GatsbyButton';

const headlineBlock = (
  <>
    Here&apos;s the boring but important stuff.
    <br />
    Terms of Use.
  </>
);

const Italic = styled(Paragraph)`
	font-style: italic;
`;
const Title = styled(H3)`
	padding-top: 40px;
`;

const Sep = () => (
  <>
    ******
    <br />
  </>
);

const PlainEnglish = () => (
  <>
    <Span>
      <b>Plain English</b>
    </Span>
    <br />
  </>
);

const TermsOfUse = () => (
  <NavOnlyTemplate
    headerProps={{
      hideButton: true
    }}
  >
    <SEO
      path={CONST.LINKS.MAIN.LEGAL.TERMS}
      title="Terms of Use"
      description="Beeline's Terms of Use policy."
    />
    <UpdateAt date="April 2020" />
    <Container>
      <H2>{headlineBlock}</H2>
      <Italic>
        <PlainEnglish />
        Have you ever heard the legendary story about Van Halen’s rider? They
        specifically included ‘no brown M&amp;M’s’ as part of their list of
        backstage requirements. Some suggested it was straight-up rockstar
        behavior — but they were wrong. The band used the kooky inclusion as a
        way to check the promoter was thorough and had read the fine print. No
        brown M&amp;Ms in their bowl of candy meant the promoter was unlikely to
        drop the ball on more important matters like staging, lighting and hair
        spray.
      </Italic>
      <Italic>
        There are no such goodies in our Terms of Use — they’re fairly standard,
        but we figured we’d give you that little piece of rock n roll history,
        as a reward for reading them. Who knows, it might come in handy at
        trivia night.
      </Italic>
      <Italic>
        You’ll see we start each section with a ‘plain English’ overview — it
        gives you a top level gist of what we’re covering. But it’s by no means
        exhaustive so if you’re serious about TOU, best you read it word for
        word.
      </Italic>
      <Italic>Now, let’s get started….</Italic>

      <Italic>
        <Sep />
        <PlainEnglish />
        When you create a profile with us or explore our website, you’re
        automatically accepting our Terms of Use (TOU). If we need to update
        this TOU, we’ll post the new version on our website.
      </Italic>
      <Paragraph>
        Beeline Loans, Inc. (“Beeline”) maintains its services (including web
        pages, sign-on, saved profile information and documentation
        (collectively, the “Content”)) to give you general information about us,
        as well as to give you financing options for your home purchase or
        refinancing needs. As used in these Terms of Use, “us”, “we”, and “our”
        refers to Beeline, its subsidiaries and affiliates. By doing the
        following, you agree to our Terms of Use (TOU) as presented in this
        document:
      </Paragraph>
      <ul>
        <li>Clicking “Create Profile” and creating a profile with us</li>
        <li>Opening the web pages beyond our homepage</li>
      </ul>

      <Paragraph>
        This TOU will govern your use and access of the services and Content,
        regardless of whether or not you:
      </Paragraph>

      <ul>
        <li>
          Read through all of the applicable terms (which may change from time
          to time without notice){' '}
        </li>
        <li>
          Complete a transaction with us through any mode of access to our
          services (website, phone, mobile phone app., email, etc.){' '}
        </li>
      </ul>

      <Paragraph>
        Any changes to our TOU will be effective upon being posted to the
        Beeline website. We recommend that any time you use or access the
        services or any Content on our website, you review the current TOU
        (including any document links). Your use and access of the services and
        Content tells us that you accept this TOU.
      </Paragraph>

      <Title>1. Contact Information</Title>
      <Italic>
        <PlainEnglish />
        If you give us your contact information, we’ll use it to contact you —
        imagine that. Sometimes a third party involved in your application may
        also use your details to reach you (for example, the appraiser). <br />
        When we get in touch with you, we may do it via SMS/text message. If
        you’d prefer we didn’t do that, call us on{' '}
        {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT} and we’ll back off.
        <br />
        We’ll also record and monitor all of our communication with you. Why? So
        we can get better at our job, and so we can detect and prevent fraud to
        your account.
      </Italic>
      <Paragraph>
        <Sep />
        By submitting your contact information, including your name, phone or
        mobile numbers, and/or email address, please be aware that we, one of
        our related companies or an unrelated one may contact you to discuss
        your inquiry. By giving us your contact information, you’re letting us
        know you’re happy for us to get in touch. Sometimes, us, or one of our
        representatives will contact you through SMS/text message. We’ll send
        them to the mobile phone number listed in your contact information. They
        may relate to your:
      </Paragraph>
      <ul>
        <li>Application status</li>
        <li>Account or loan information </li>
        <li>Additional information or documentation requests </li>
        <li>Due dates </li>
        <li>Delinquent accounts </li>
        <li>Closings </li>
        <li>Program updates</li>
      </ul>

      <Paragraph>
        At any time, you can tell us you don’t want texts by calling us at{' '}
        {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}. If the mobile phone number
        listed in your contact information is invalid or becomes unreachable,
        we’ll consider this a withdrawal of your consent to receive text
        notifications. After you’ve withdrawn your consent, we’ll need a little
        time to process it.
      </Paragraph>
      <Paragraph>
        If you need more information, call us on{' '}
        {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}. To access, view and keep texts
        that we send you, your mobile phone will need to be SMS-capable and have
        enough storage. It might go without saying, but your account will also
        need to be active with a mobile phone communications provider. When we
        send you a text message, we’ve given it to you ‘in writing’. We don’t
        charge a service fee to send you texts — if your phone company, or other
        third parties charge for texts, you’re responsible for those charges.
        Check with your phone company if you’re unsure about any charges. At any
        time and for any reason, we may change or stop our text messaging
        services. This may be done with or without prior notice and with no
        liability to you.
      </Paragraph>
      <Paragraph>
        Just FYI — your chat, email, text message, online, and telephone
        communications with us will be monitored or recorded. We do this to
        improve our customer service, as well as to help us detect and prevent
        fraud to your account. When you click the “accept” button (or similar)
        on your communications device, you’re letting us know you’re okay to
        have your conversation/information exchange recorded and monitored when
        you deal with us.
      </Paragraph>

      <Title>2. Your Information and Credentials</Title>
      <Italic>
        <PlainEnglish />
        As part of your application, you’ll be giving us personal information —
        it’s up to you to make sure that info is as legit and up-to-date as
        possible. Occasionally, we may need to share your non-personal
        information with third parties who are involved in your application, so
        appraisers, title companies, etc.
        <br />
        If you’re applying with a co-buyer, you’ll share a login to our system
        but you’ll be our main point of contact and in charge of keeping your
        co-buyer in the loop.
      </Italic>
      <Paragraph>
        <Sep />
        When doing your home loan application, you’ll need to give us certain
        information. Regardless of how you send it to us (email, website upload,
        etc.), we don’t claim to have ownership of it and/or your communications
        or feedback to us, nor do any of our affiliated business partners. By
        giving us this information, you’re giving us and any vendors involved in
        your home loan, permission to use it to complete the loan (including,
        but not limited to, verifying your employment, assets, liabilities,
        credit score, identification). This allows us to copy, distribute,
        transmit, reproduce, edit, translate and reformat the information you’ve
        given us. To complete your home loan, we may also need to share your
        non-personal information with third parties like the appraiser, title
        company, etc. Just so you know, the terms and conditions listed on our
        website related to these permissions may change and will overrule this
        version
      </Paragraph>
      <Paragraph>
        If you’re applying for any of our products with a co-buyer, each of you
        will use the same login to our system. As the primary applicant, you
        agree that you will be the main point of contact for this transaction
        and are responsible for making sure that the co-buyer is kept up to date
        throughout this process. The co-buyer must personally authorize us to
        run their credit report and must use their own credentials for accessing
        their bank account, asset, tax or employment information. When we get
        the co-buyer’s information, credentials and credit report authorization,
        we’ll take that as their permission to access this information, and
        you’ll hold us harmless from any action(s) by the co-buyer if these
        instructions aren’t followed.
      </Paragraph>
      <Paragraph>
        It’s essential that all communications, documentation and information
        you give us is true and reasonable and that none of it has been
        intentionally misrepresented nor information left out that would
        materially affect our evaluation of your home loan request.
      </Paragraph>
      <Paragraph>
        We generally start your home loan request when we have received all of
        the information needed to complete your application. When you submit an
        application to us, you agree to assist us in this process to make sure
        it’s done as fast as possible. This will include but is not limited to
        the following:
      </Paragraph>
      <ul>
        <li>
          Sending us all of the required documentation requested in a timely
          manner
        </li>
        <li>
          As requested, get any information that we may need from third parties
          (your bank, employer, current mortgage company, etc.){' '}
        </li>
        <li>
          Promptly let us know of changes to any of the information that you
          have given to us for this application
        </li>
      </ul>
      <Paragraph>
        For computer security purposes, we’ll collect and store information that
        you provide when you set up an account or reset your password on our
        network. The email address, phone number and password that you use to
        create your account will be the credentials used by our network to check
        that you’re authorized to access your account.
      </Paragraph>
      <Paragraph>
        You agree not to share your credentials, and to take reasonable steps to
        keep them secure, and confidential. You’ll also need to contact us
        immediately if you think these or other identifying information has been
        compromised, lost, or stolen.&nbsp;
        <b>
          Now, this is important (that’s why it’s in bold): we will never
          contact you, whether it be through an unsolicited email, a phone call
          or other means of communication, requesting that you give us your
          username and or password.
        </b>
      </Paragraph>

      <Title>3. Content</Title>
      <Italic>
        <PlainEnglish />
        Our content is great, we wouldn’t blame you for wanting to steal it —
        but please don’t.
      </Italic>
      <Paragraph>
        <Sep />
        All content, (website information and text, graphics, logos, images,
        audio clips, video, data, music, software, and other material
        (collectively “Materials”)) is property owned or licensed to us or to
        our licensors and is protected by U.S. and international copyright laws,
        trademark, patent, or other proprietary rights. The collection,
        arrangement and assembly of all Materials is our exclusive property and
        we, as well as our licensors, expressly reserve all intellectual
        property rights in and to all of these Materials. By accessing or using
        our services and Materials, you confirm and agree that you gain no
        rights to these and will not copy or distribute any of them. Subject to
        the restrictions presented in this TOU, we grant you a limited,
        non-exclusive license to use these services and Materials.
      </Paragraph>

      <Title>4. Reasonable Efforts</Title>
      <Italic>
        <PlainEnglish />
        Getting a home loan involves a lot of moving parts so sometimes there’ll
        be unexpected delays. We’ll try our best to avoid pushing out any
        deadlines but we won’t be held responsible if any do pop up.
      </Italic>
      <Paragraph>
        <Sep />
        We’ll make every reasonable effort to have your home loan request
        processed and closed on or before the end date of any rate lock you may
        have, as well as on or before your targeted closing date. Unfortunately,
        some unexpected delays may arise that are beyond our control and will
        prevent us from meeting deadlines. In these instances, we cannot be held
        responsible for delays. Some examples of the types of delays that may
        prevent us from meeting these deadlines are:
      </Paragraph>
      <ul>
        <li>
          An acceptable appraisal or other documentation required to approve
          your loan arrives later than planned{' '}
        </li>
        <li>Your existing home is not selling or has not sold</li>
        <li>Unresolved matters found by the title commitment work or survey</li>
        <li>Any other matters beyond our reasonable control</li>
      </ul>

      <Title>5. Copyright and Trademarks</Title>
      <Italic>
        <PlainEnglish />
        All of our stuff is protected by the little ‘c’ and ‘™’ so if you try to
        use it, we’ll be very cranky.
      </Italic>
      <Paragraph>
        <Sep />
        The Materials are copyrighted and are not to be reproduced, distributed,
        modified or used in violation of copyright law. You may not use any of
        the contents of the Materials for public or commercial purposes without
        our prior written consent. We do not guarantee that your use of these
        Materials will be protected from any infringement claims of third
        parties. Our name, logo and any other marks used in the Materials that
        identify the origin of our information, goods or services, are our
        trademarks. Any other trademarks used are the property of their
        respective owners.
      </Paragraph>

      <Title>6. No Reliance</Title>
      <Italic>
        <PlainEnglish />
        We work hard to make sure our information is always up-to-date and
        accurate. But before you make any major decisions, just double check the
        numbers and information.
      </Italic>
      <Paragraph>
        <Sep />
        While we make every effort to keep the information in our Materials and
        related to our services current and accurate, we don’t warrant or
        represent (express or implied) that it will always be spot on. As a
        result, you’re strongly advised not to take any action or make any
        decisions by relying on this information as presented. We may not be
        held legally responsible for any errors or omissions in the services or
        Materials as presented.
      </Paragraph>

      <Title>7. Disclaimer of Contract</Title>
      <Italic>
        <PlainEnglish />
        Yes this TOU is long, and that’s on purpose — it’s the sole document
        that controls your use of, and interaction with, our website, portal
        etc.
      </Italic>
      <Paragraph>
        <Sep />
        Except for this TOU, no statement in our Materials or services
        constitutes a separate contract, a representation, warranty or an offer
        of employment or to enter into a contract. In addition, no statement
        made by us (or shall be deemed to be) whether by implying, reference to
        or otherwise may be inserted into any contract or employment
        relationship of ours. The only terms and conditions applicable to
        performance by us are those that are expressly stated in a separate
        written agreement executed by us. By offering information, products or
        services online, no request is being made by us to any person to use any
        of these in jurisdictions where providing this information, products or
        services is prohibited by law.
      </Paragraph>

      <Title>8. Limitation of Liability and Disclaimer of Warranties</Title>
      <Italic>
        <PlainEnglish />
        Firstly, let us apologize about this next bit being in capitals —
        apparently it’s very serious and important. We just need to let you know
        that when you use our services, it’s at your own risk, in capitals.
      </Italic>
      <Paragraph>
        <Sep />
        UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DIRECT OR INDIRECT,
        SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES THAT MAY ARISE IN
        CONNECTION WITH THE SERVICES OR MATERIALS, OR FROM YOUR USE OF, OR
        INABILITY TO USE, OUR WEBSITE OR ANY INFORMATION PROVIDED ON THE
        WEBSITE; OR IN CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR,
        OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION,
        COMPUTER VIRUS OR LINE OR SYSTEM FAILURE; OR DISCLOSURE OF INFORMATION
        WHEN REPLYING TO YOU BY EMAIL OR OTHER ELECTRONIC MEANS OF RECEIVING
        EMAILS FROM YOU; EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES, LOSSES, OR EXPENSES. *Aaaaand deep breath*. Some jurisdictions
        do not allow the exclusion or limitation of liability for consequential
        or incidental damages. In such jurisdictions, our liability is limited
        to the greatest extent permitted by law.
      </Paragraph>
      <Paragraph>
        Your use of our services and Materials is entirely at your own risk.
        Neither we, our directors, affiliates nor any other party involved in
        creating, producing or delivering our services or Materials will be
        liable for any direct, incidental, consequential, indirect or punitive
        damages that occur when you access or use our services or Materials,
        regardless of the theory or basis upon which damages are claimed. The
        content and functionality of this website is provided to you AS IS AND
        WITHOUT WARRANTY OF ANY KIND, EXPRESSED OR IMPLIED. THE IMPLIED
        WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE
        DISCLAIMED. Some states may not allow the exclusion of implied
        warranties, so some of these exclusions may not apply to you. In no
        event will we promise or warrant the correctness, comprehensiveness,
        completeness, accuracy, timeliness, merchantability or fitness of any
        information or any of our services or Materials accessed or used by you.
        No action arising out of, or pertaining to this TOU may be brought more
        than one (1) year after it initially occurred.
      </Paragraph>

      <Title>9. Damage to Property</Title>
      <Italic>
        <PlainEnglish />
        Sometimes things go awry when you’re using the internet — we like to
        think we’re all over it when it comes to protecting your information.
        Our technology was actually built GDPR compliant, which basically means
        it exceeds the minimum security industry requirements. All that said,
        sometimes things can happen beyond our control, so protect your computer
        with virus-detection software, etc. because we won’t be held responsible
        for any damage.
      </Italic>
      <Paragraph>
        <Sep />
        Electronic communications, databases and websites are subject to errors,
        malfunctions, tampering and break-ins which could damage your systems or
        operations. We don’t accept responsibility for, nor will we be liable
        for any damages to your computer equipment, hardware, software or any
        other property when using our services or Materials. It’s your
        responsibility to have the appropriate safeguards (ie. virus-detection
        software, etc.) in place to protect your information technology assets.
      </Paragraph>

      <Title>10. Modification and Termination</Title>
      <Italic>
        <PlainEnglish />
        Occasionally, we may update these Terms of Use. If you keep using our
        services, we’ll take that to mean you’re okay with the new terms. We may
        also need to ban you, temporarily or permanently from our site and
        deactivate your profile. It sounds a bit drastic but we’d never do it
        unnecessarily.
      </Italic>
      <Paragraph>
        <Sep />
        Periodically, we may change this TOU. By continuing to use our services
        or Materials after any changes have been posted, you agree to comply
        with the updated TOU. You acknowledge and agree that any changes made to
        this TOU by anyone but us is not allowed and will not be accepted nor
        considered effective.
      </Paragraph>
      <Paragraph>
        In our sole discretion, without notice and for any or no reason, we
        reserve the right to terminate, temporarily or permanently, your use of
        the Materials, as well as to block or prevent you from future access to
        and use of these. Upon termination, your right to use the Materials will
        immediately end. You agree that any termination of your access to or use
        of these may be put in place without prior notice. We may immediately
        deactivate or delete your password and username, all information and
        files associated with it and deny you any further access to any such
        information or files. You agree that we shall not be liable to you or
        any third party for any termination or denial of your access to the
        Materials.
      </Paragraph>

      <Title>11. No Unlawful or Prohibited Uses</Title>
      <Italic>
        <PlainEnglish />
        Don’t do anything illegal with our services and materials, and we’ll get
        along just fine. Oh, and you’re happy for us to verify your identity
        with your phone company.
      </Italic>
      <Paragraph>
        <Sep />
        The services and Materials may only be used for lawful purposes and only
        when you are looking to conduct business with us. As a condition of
        this, you agree that you will not use these services or Materials for
        any purpose that is unlawful or prohibited by this TOU. You authorize
        your wireless operator (i.e. AT&amp;T, Sprint, T-Mobile, US Cellular,
        Verizon, or any other branded wireless operator) to use your mobile
        number, name, address, email, network status, customer type and role,
        billing type, mobile device identifiers (IMSI and IMEI) and other
        subscriber status details to allow us to verify your identity and to
        compare information that you have provided to us to your wireless
        account profile. This requirement will be in effect for the duration of
        our business relationship. See our Privacy Policy for how we treat your
        data.
      </Paragraph>

      <Title>12. Links to Third Parties’ Websites</Title>
      <Italic>
        <PlainEnglish />
        Occasionally, we may provide you links to other relevant and helpful
        websites — we’re not responsible for any content, privacy settings,
        terms and conditions, or any general weirdness on those sites.
      </Italic>
      <Paragraph>
        <Sep />
        For your convenience, we may provide links to other websites. The
        services and Materials we offer may contain links and interactive
        capabilities with the websites of third parties. We haven’t reviewed all
        of those third party websites and are not responsible for, nor have any
        liability for the functionality, actions, inactions, privacy settings,
        privacy policies, terms and conditions and content that may appear on
        them. The links and interactive capabilities presented for third party
        websites in no way represents our endorsement of these websites. YOUR
        USE OF THIRD PARTY WEBSITES AND RESOURCES IS AT YOUR OWN RISK. You are
        responsible for adherence to their terms, conditions and disclaimers. At
        our discretion, we reserve the right to terminate a link to a third
        party website at any time. You agree that if you participate, we may
        share the information that you provide, including, but not limited to,
        your full name, birth date, address, telephone number, and social
        security number with our partners so that they may provide us with
        information needed to process your home loan application. For more
        information about how we use your information and other privacy
        information, please review our Privacy Policy located{' '}
        <ButtonText to={CONST.LINKS.MAIN.LEGAL.DEFAULT} selected>
          here
        </ButtonText>
        .
      </Paragraph>

      <Title>13. Cookies</Title>
      <Paragraph>
        A cookie is a small data file that is placed on the hard drive of your
        computer when you visit a website. Please see our Privacy Policy
        regarding our use of cookies.
      </Paragraph>

      <Title>14. Mobile Privacy</Title>
      <Paragraph>
        We may offer mobile applications (commonly known as “apps”) that allow
        you to provide personal information such as images of documents to us.
        All personal information collected by us through this app is protected
        by our privacy policy.
      </Paragraph>

      <Title>15. Social Media Interactions</Title>
      <Italic>
        <PlainEnglish />
        Hey, go crazy on our social media but know that you may be sharing
        personal info.
      </Italic>
      <Paragraph>
        <Sep />
        We encourage you to share your experience with us with your friends
        using social media such as Facebook, Twitter, Pinterest, and Instagram.
        If you choose to use social media, you may be sharing certain personal
        profile elements, including your comments posted on these sites. This
        activity is subject to each social media program’s privacy policies.
      </Paragraph>

      <Title>
        16. Reviews, Comments, Submissions, and Other Communications
      </Title>
      <Italic>
        <PlainEnglish />
        We’d love you to share your thoughts online but if you do, there are a
        few things to keep in mind...
      </Italic>
      <Paragraph>
        <Sep />
        You agree that your content will:
      </Paragraph>
      <ul>
        <li>Be accurate </li>
        <li>Not violate or promote the violation of any law or regulation </li>
        <li>
          Not violate any right of a third party, including copyright,
          trademark, privacy, or publicity rights{' '}
        </li>
        <li>Not cause injury to any person or entity</li>
        <li>
          Not contain, or provide links to, obscene, profane or threatening
          language, malware, political campaigning, commercial solicitation,
          chain letters, mass mailings, any form of “spam” or any material that
          could be considered harmful, sexually explicit, indecent, lewd,
          violent, abusive or degrading.
        </li>
      </ul>
      <Paragraph>
        You are solely responsible for the content that you submit and we assume
        no liability for any content submitted by you. You agree that, in our
        sole discretion, we reserve the right to do any or all of the following:
      </Paragraph>
      <ul>
        <li>Monitor your content </li>
        <li>
          Alter, remove, refuse to post or allow to be posted any content;
        </li>
        <li>
          Disclose any content and the circumstances surrounding its
          transmission to any third party.
        </li>
      </ul>
      <Paragraph>
        For any content that you submit, you grant to us a non-exclusive,
        sub-licensable, fully paid-up, perpetual, irrevocable, royalty-free,
        transferable right and license to use, display, perform, transmit, copy,
        modify, delete, adapt, publish, translate, create imitated works from,
        sell and distribute such content and to incorporate it into any form,
        medium or technology, now known or hereafter developed, throughout the
        world, all without compensation to you. For this reason, do not send us
        any content that you do not wish to license to us, including any
        confidential information or any original creative materials such as
        stories, product ideas, computer code or original artwork. In addition,
        you grant to us the right to include the name provided along with the
        content submitted by you provided, however, we have no obligation to
        include such name with such content. We are not responsible for the use
        or disclosure of any personal information that you voluntarily disclose
        in connection with any content that you submit. You represent and
        warrant that you have all rights necessary for you to grant the licenses
        granted in this section. You further irrevocably waive any “moral
        rights” or other rights with respect to attribution of authorship or
        integrity of materials regarding your content that you may have under
        any applicable law under any legal theory.
      </Paragraph>
      <Paragraph>
        Content is also provided by third party visitors. Please note that
        visitors may post content that is inaccurate, misleading or deceptive.
        We neither endorse nor are we responsible for any opinion, advice,
        information, or statements made by third parties. We will not be liable
        for any loss or damage caused by your reliance on such information or
        materials. The opinions expressed by third parties reflect solely the
        opinions of the individuals who submitted such opinions and may not
        reflect our opinions.
      </Paragraph>

      <Title>17. Indemnification and Defense</Title>
      <Italic>
        <PlainEnglish />
        If anything goes pear-shaped when you’re using our services and
        materials, we won’t be held responsible. Also, if anyone else uses your
        credentials, you’ll be held responsible for all damages and claims that
        may come about from their usage.
      </Italic>
      <Paragraph>
        <Sep />
        As a condition of the use of the services and Materials, you agree to
        defend, indemnify, and hold us, (and our respective employees,
        directors, officers, agents, vendors and suppliers) harmless from and
        against any liabilities, losses, investigations, inquiries, claims,
        suits, damages, costs, and expenses (including, without limitation,
        reasonable attorneys’ fees and expenses) (each, a “Claim”) arising out
        of, or relating to your use of our services or Materials. This includes
        without limitation, any content submitted by you and Claims alleging
        facts that if true would constitute a breach by you of this TOU. You are
        solely responsible for all damages or claims that may arise from any
        access to, or use of, the services and/or Materials by any person using
        your credentials.
      </Paragraph>

      <Title>18. Notices and Electronic Communications</Title>
      <Italic>
        <PlainEnglish />
        Occasionally, we may need to send you a notice (they usually contain
        legal/compliance info). We can share them with you in lots of ways —
        email, mail, via our website. We consider any message sent through those
        avenues to be ‘in writing’ and that notice is given 24 hours after it’s
        sent, and 3 days after if it was sent in the mail.
      </Italic>
      <Paragraph>
        <Sep />
        Except as explicitly stated otherwise, you should send any notices to us
        by mail at: {CONST.ADDRESS}, Attn: Legal Department.
      </Paragraph>
      <Paragraph>
        In the case of notices we send to you, you agree to receive those
        notices and other communications by us posting notices to the Beeline
        website, sending you an email at the email address you previously gave
        to us or mailing a notice to you at your address that you have given to
        us. You agree that all agreements, notices, disclosures and other
        communications that we provide to you in accordance with the prior
        sentence satisfy any legal requirement that such communications be in
        writing. Notice shall be considered given 24 hours after the notice is
        posted on the Beeline website or an electronic message is sent, unless
        the sending party is notified that the message did not reach the
        recipient or if by mail service, three days after the date of mailing.
        You agree that a printed version of the TOU and/or any notice given in
        electronic form shall be admissible in a judicial or administrative
        proceeding. The requirements for these will be based on the conditions
        presented in this TOU for other business documents and records that were
        originally generated and maintained in printed form.
      </Paragraph>

      <Title>19. Rules for Promotions</Title>
      <Paragraph>
        Any sweepstakes, contests, or other promotions (collectively,
        “Promotions”) made available by us may be governed by rules that are
        separate from these TOU. If you participate in any Promotions, please
        review the applicable rules as well as our Privacy Policy. If the rules
        for a Promotion conflict with this TOU, the Promotion rules will apply.
      </Paragraph>

      <Title>20. Miscellaneous</Title>
      <Italic>
        <PlainEnglish />
        Lots of big words in this one (‘contemporaneous’ — we’re looking at
        you). Basically, we’re letting you know the TOU is watertight and that
        it applies to residents of the United States.
      </Italic>
      <Paragraph>
        <Sep />
        The TOU, including policies and information linked from or incorporated
        herein, constitute the entire agreement between you and us with respect
        to the Materials and services and supersede all prior or contemporaneous
        communications, agreements, and proposals with respect thereto. No
        provision of these TOU shall be waived except pursuant to a writing
        executed by the party against whom the waiver is sought. No failure to
        exercise, partial exercise of, or delay in exercising any right or
        remedy under these TOU shall operate as a waiver or estoppel of any
        right, remedy, or condition. If any provision of these TOU is held
        invalid, illegal or unenforceable, the validity, legality and
        enforceability of the remaining provisions will not be affected or
        impaired. You may not assign, transfer, or sublicense any of your rights
        or obligations under these TOU without our express prior written
        consent. We will not be responsible for failure to fulfill any
        obligation due to causes beyond our control. We provide the services and
        Materials to residents of the United States. New York law, without
        giving effect to its principles of conflicts of law, governs all
        adversarial proceedings arising from this TOU, Materials or services.
        Any action at law or in equity arising out of, or relating to, this TOU
        or the use of the website or services must be filed and resolved only in
        a state or federal court located in Suffolk County, New York.
      </Paragraph>

      <Title>21. Contact Us</Title>
      <Paragraph>
        If you have any concerns about us or your use of the services or
        Materials, get in touch.
        <br />
        Telephone:{' '}
        <ButtonText to={`tel:${CONST.LINKS.EXTERNAL.PHONE.DEFAULT}`} selected>
          {CONST.LINKS.EXTERNAL.PHONE.DASHED_INT}
        </ButtonText>
        <br />
        Email:{' '}
        <ButtonText
          to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`}
          selected
        >
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>
      </Paragraph>

      <Title>22. Your California Privacy Rights</Title>
      <Paragraph>
        California Civil Code § 1798.83 permits our visitors who are California
        residents to request certain information regarding their disclosure of
        personal information to third parties for their direct marketing
        purposes. To make such a request, please send an email to{' '}
        <ButtonText
          to={`mailto:${CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}`}
          selected
        >
          {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
        </ButtonText>{' '}
        or write us:
      </Paragraph>
      <Paragraph>
        Beeline Loans, Inc.
        <br />
        {CONST.ADDRESS}
        <br />
        Attn: Legal Department
      </Paragraph>
    </Container>
  </NavOnlyTemplate>
);

export default TermsOfUse;
